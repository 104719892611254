import React from "react";

function FigmaPromo() {
  return (
    <div className="rounded-md h-[350px] overflow-hidden bg-purple-400/50 w-full border border-purple-600 outline outline-4 outline-purple-400/5">
      <div className="flex flex-wrap flex-1 tracking-[-0.05px] p-4">
        <div className="items-center w-full">
          <span>Use our</span>
          <svg
            width="11"
            className="mx-0.5 ml-1 inline-block"
            height="15"
            viewBox="0 0 11 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.90752 5.61312H5.04002V0H7.90752C9.45502 0 10.7138 1.25875 10.7138 2.80625C10.7138 4.35375 9.45502 5.61312 7.90752 5.61312ZM5.95939 4.69375H7.90752C8.94814 4.69375 9.7944 3.84688 9.7944 2.80688C9.7944 1.76688 8.94752 0.92 7.90752 0.92H5.95939V4.69375ZM5.95939 5.61312H3.09252C1.54502 5.61312 0.286269 4.35438 0.286269 2.80688C0.286269 1.25938 1.54502 0 3.09252 0H5.96002V5.61312H5.95939ZM3.09252 0.919375C2.05189 0.919375 1.20564 1.76625 1.20564 2.80625C1.20564 3.84625 2.05189 4.69375 3.09252 4.69375H5.04064V0.919375H3.09252ZM5.95939 10.3062H3.09252C1.54502 10.3062 0.286269 9.0475 0.286269 7.5C0.286269 5.9525 1.54502 4.69375 3.09252 4.69375H5.96002V10.3062H5.95939ZM3.09252 5.61312C2.05189 5.61312 1.20564 6.46 1.20564 7.5C1.20564 8.54 2.05252 9.38688 3.09252 9.38688H5.04064V5.61312H3.09252ZM3.10752 15C1.55189 15 0.285645 13.7413 0.285645 12.1938C0.285645 10.6462 1.54439 9.3875 3.09189 9.3875H5.95939V12.1631C5.95939 13.7275 4.68002 15 3.10752 15ZM3.09252 10.3062C2.59229 10.3069 2.11274 10.5059 1.75903 10.8596C1.40531 11.2133 1.20631 11.6929 1.20564 12.1931C1.20564 13.2338 2.05877 14.08 3.10814 14.08C4.17377 14.08 5.04127 13.22 5.04127 12.1625V10.3062H3.09252ZM7.90752 10.3062H7.84627C6.29877 10.3062 5.04002 9.0475 5.04002 7.5C5.04002 5.9525 6.29877 4.69375 7.84627 4.69375H7.90752C9.45502 4.69375 10.7138 5.9525 10.7138 7.5C10.7138 9.0475 9.45502 10.3062 7.90752 10.3062ZM7.84689 5.61312C6.80627 5.61312 5.96002 6.46 5.96002 7.5C5.96002 8.54 6.80689 9.38688 7.84689 9.38688H7.90814C8.94877 9.38688 9.79502 8.54 9.79502 7.5C9.79502 6.46 8.94752 5.61312 7.90752 5.61312H7.84689Z"
              fill="black"
            />
          </svg>

          <span>
            {" "}
            plugin to instantly create animated prototypes without leaving Figma
          </span>
        </div>
        <div className="my-6 flex flex-col">
          <button
            className="font-semibold flex items-center bg-purple-600 text-white px-3 py-1.5 outline outline-2 outline-white/20 rounded-md"
            data-signup
          >
            {" "}
            <svg
              width="11"
              className="mr-2 inline-block"
              height="15"
              viewBox="0 0 11 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.90752 5.61312H5.04002V0H7.90752C9.45502 0 10.7138 1.25875 10.7138 2.80625C10.7138 4.35375 9.45502 5.61312 7.90752 5.61312ZM5.95939 4.69375H7.90752C8.94814 4.69375 9.7944 3.84688 9.7944 2.80688C9.7944 1.76688 8.94752 0.92 7.90752 0.92H5.95939V4.69375ZM5.95939 5.61312H3.09252C1.54502 5.61312 0.286269 4.35438 0.286269 2.80688C0.286269 1.25938 1.54502 0 3.09252 0H5.96002V5.61312H5.95939ZM3.09252 0.919375C2.05189 0.919375 1.20564 1.76625 1.20564 2.80625C1.20564 3.84625 2.05189 4.69375 3.09252 4.69375H5.04064V0.919375H3.09252ZM5.95939 10.3062H3.09252C1.54502 10.3062 0.286269 9.0475 0.286269 7.5C0.286269 5.9525 1.54502 4.69375 3.09252 4.69375H5.96002V10.3062H5.95939ZM3.09252 5.61312C2.05189 5.61312 1.20564 6.46 1.20564 7.5C1.20564 8.54 2.05252 9.38688 3.09252 9.38688H5.04064V5.61312H3.09252ZM3.10752 15C1.55189 15 0.285645 13.7413 0.285645 12.1938C0.285645 10.6462 1.54439 9.3875 3.09189 9.3875H5.95939V12.1631C5.95939 13.7275 4.68002 15 3.10752 15ZM3.09252 10.3062C2.59229 10.3069 2.11274 10.5059 1.75903 10.8596C1.40531 11.2133 1.20631 11.6929 1.20564 12.1931C1.20564 13.2338 2.05877 14.08 3.10814 14.08C4.17377 14.08 5.04127 13.22 5.04127 12.1625V10.3062H3.09252ZM7.90752 10.3062H7.84627C6.29877 10.3062 5.04002 9.0475 5.04002 7.5C5.04002 5.9525 6.29877 4.69375 7.84627 4.69375H7.90752C9.45502 4.69375 10.7138 5.9525 10.7138 7.5C10.7138 9.0475 9.45502 10.3062 7.90752 10.3062ZM7.84689 5.61312C6.80627 5.61312 5.96002 6.46 5.96002 7.5C5.96002 8.54 6.80689 9.38688 7.84689 9.38688H7.90814C8.94877 9.38688 9.79502 8.54 9.79502 7.5C9.79502 6.46 8.94752 5.61312 7.90752 5.61312H7.84689Z"
                fill="white"
              />
            </svg>
            Use plugin{" "}
          </button>
        </div>
      </div>
      <div className="flex items-center flex-1 translate-x-[25%] mt-4 overflow-hidden">
        <img
          className="rounded-[3px]"
          src="https://s3.eu-west-2.amazonaws.com/assets.rombo.co/RomboFigmaPlugin.gif"
        />
      </div>
    </div>
  );
}

export default FigmaPromo;
