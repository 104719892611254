import React, { Fragment, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Auth from "@aws-amplify/auth";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import AnimationsList from "../Components/AnimationsList";
import Receiver from "../Components/Receiver";
import Installer from "../Components/Installer";
import Cloner from "../Components/Cloner";
import Playground from "../Components/Playground";
import "./Animations.css";
import { GET_APP_ANIMATIONS, GET_APP, CHECK_INSTALLATION } from "../Queries";
import {
  ADD_ANIMATION,
  UPDATE_ANIMATION,
  REMOVE_ANIMATION,
  RESET_ANIMATIONS,
  DOWNLOAD_SITE,
} from "../Mutations";
import Figma from "../Components/Figma";
import ExtensionPromo from "../Components/ExtensionPromo";
import FigmaPromo from "../Components/FigmaPromo";
import WebPromo from "../Components/WebPromo";
function isLocalhost(url) {
  const pattern = /^(https?:\/\/)?(localhost|127\.0\.0\.1)/;
  return pattern.test(url);
}

function PlatformSelect() {
  return (
    <div className="relative">
      <div className="flex gap-5 mx-10 mt-12">
        <div className="flex-1 relative">
          <div className="absolute h-36 w-0.5 bg-dark-100 left-[60%] rotate-[-55deg] bottom-[45px] translate-y-full"></div>
          <ExtensionPromo />
        </div>
        <div className="flex-1 relative">
          <div className="absolute h-20 w-0.5 z-0 bg-dark-100 left-1/2 rotate-[0deg] bottom-[15px] translate-y-full"></div>
          <FigmaPromo />
        </div>
        <div className="flex-1 relative">
          <div className="absolute h-36 w-0.5 bg-dark-100 right-[60%] rotate-[55deg] bottom-[45px] translate-y-full"></div>

          <WebPromo />
        </div>
      </div>

      <div className="relative flex flex-row justify-center items-center mx-auto w-1/2 mt-8 bg-dark-100/20 opacity-95 outline outline-2 backdrop-blur-3xl text-dark-900 outline-dark-800/5 p-4 rounded-lg">
        <svg
          width="32"
          viewBox="0 0 24 25"
          fill="none"
          className="pr-2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_501_68)">
            <path
              d="M17.9647 2.79297H5.63879C5.67887 1.99929 6.17975 1.54297 7.01169 1.54297H16.5918C17.4237 1.54297 17.9246 1.99929 17.9647 2.79297Z"
              fill="black"
              fill-opacity="0.85"
            />
            <path
              d="M19.9623 5.5825C19.6482 5.53257 19.3151 5.50781 18.9648 5.50781H4.6387C4.28853 5.50781 3.95547 5.53256 3.64148 5.58247C3.74781 4.64426 4.38266 4.12109 5.41995 4.12109H18.1934C19.2307 4.12109 19.8575 4.64428 19.9623 5.5825Z"
              fill="black"
              fill-opacity="0.85"
            />
            <path
              d="M4.63867 23.1641H18.9648C21.0059 23.1641 22.0312 22.1484 22.0312 20.1367V10.1074C22.0312 8.0957 21.0059 7.08008 18.9648 7.08008H4.63867C2.59766 7.08008 1.57227 8.0957 1.57227 10.1074V20.1367C1.57227 22.1484 2.59766 23.1641 4.63867 23.1641ZM4.66797 21.5918C3.69141 21.5918 3.14453 21.0645 3.14453 20.0488V10.1953C3.14453 9.17969 3.69141 8.65234 4.66797 8.65234H18.9453C19.9023 8.65234 20.459 9.17969 20.459 10.1953V20.0488C20.459 21.0645 19.9023 21.5918 18.9453 21.5918H4.66797Z"
              fill="black"
              fill-opacity="0.85"
            />
            <path
              d="M12.2754 19.0625C12.4609 19.0625 12.5879 18.9355 12.627 18.75C13.1641 15.8789 13.4277 15.3418 16.416 14.9414C16.6113 14.9121 16.748 14.7754 16.748 14.5898C16.748 14.4043 16.6113 14.2676 16.416 14.2285C13.4375 13.8281 13.0566 13.2617 12.627 10.4395C12.5977 10.2441 12.4609 10.1172 12.2754 10.1172C12.0996 10.1172 11.9531 10.2344 11.9141 10.4297C11.377 13.3105 11.1133 13.8281 8.125 14.2285C7.92969 14.2676 7.80273 14.4043 7.80273 14.5898C7.80273 14.7754 7.92969 14.9121 8.125 14.9414C11.123 15.2441 11.4941 15.8789 11.9141 18.7402C11.9434 18.9355 12.0801 19.0625 12.2754 19.0625ZM8.4375 20.6543C8.56445 20.6543 8.67188 20.5664 8.70117 20.4395C8.97461 19.0137 8.87695 18.9453 10.4297 18.7109C10.5664 18.6914 10.6543 18.5742 10.6543 18.4375C10.6543 18.3105 10.5664 18.1934 10.4297 18.1738C8.87695 17.9199 8.97461 17.8516 8.70117 16.4746C8.67188 16.3281 8.57422 16.2207 8.4375 16.2207C8.30078 16.2207 8.20312 16.3184 8.16406 16.4746C7.88086 17.8516 7.99805 17.9199 6.44531 18.1738C6.29883 18.1934 6.2207 18.3105 6.2207 18.4375C6.2207 18.5938 6.29883 18.6914 6.46484 18.7109C7.99805 18.9453 7.88086 19.0137 8.16406 20.4102C8.20312 20.5566 8.29102 20.6543 8.4375 20.6543Z"
              fill="black"
              fill-opacity="0.85"
            />
          </g>
          <defs>
            <clipPath id="clip0_501_68">
              <rect width="23.9648" height="24.7363" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p className="text-center font-semibold text-xl">
          Rombo Animation Library
        </p>
      </div>
    </div>
  );
}

function Animations({ auth }) {
  // let navigate = useNavigate();
  const { app_id } = useParams();

  const {
    data: animationsData,
    loading: animationsLoading,
    error: animationsError,
  } = useQuery(GET_APP_ANIMATIONS, {
    variables: { app_id },
  });

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery(GET_APP, {
    variables: { app_id },
  });

  const {
    data: installData,
    loading: installLoading,
    error: installError,
    refetch: refetchInstallChecker,
  } = useQuery(CHECK_INSTALLATION, {
    variables: { app_id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    // pollInterval: 3000,
  });
  const [removeAnimation, removingAnimation] = useMutation(REMOVE_ANIMATION);
  const [modalState, setModalState] = useState(false);
  const [element, setElement] = useState(null);
  const [downloadSite] = useMutation(DOWNLOAD_SITE);
  let [clonedUrl, setClonedUrl] = useState(appData?.getApp?.cloned_url);
  const [openModal, setOpenModal] = useState(null);

  async function download() {
    const { data } = await downloadSite({
      variables: { app_id, app_url: appData?.getApp?.app_url },
    });
    setClonedUrl(data.downloadSite.cloned_url);
  }

  const location = useLocation();

  useEffect(() => {
    // If params include ?preview=true
    // Open the preview modal

    const searchParams = new URLSearchParams(location.search);
    const previewParam = searchParams.get("preview");

    if (previewParam) {
      setModalState(true);
    }
  }, [location?.search]);

  React.useEffect(() => {
    if (!appData?.getApp?.cloned_url) {
      download();
    }
    // eslint-disable-next-line
  }, [appData && !clonedUrl]);

  if (animationsLoading || installLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <aside></aside>
      </div>
    );
  }
  return (
    <section className="animations-holder">
      {openModal && (
        <div
          className="w-full align-center justify-center bg-white bg-opacity-70 items-center flex fixed backdrop-blur-sm h-full left-0 z-50"
          onClick={() => setOpenModal(false)}
        >
          <div
            className="w-1/2 h-2/3"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              onClick={() => setOpenModal(false)}
              className="opacity-50 hover:opacity-100 cursor-pointer text-md p-2 rounded-md"
            >
              Close x
            </div>
            {openModal === "install" ? (
              <Installer
                checking={installLoading}
                platform={installData?.checkInstallation?.platform}
                app_url={appData?.getApp?.app_url}
                app_id={app_id}
                refetch={refetchInstallChecker}
                {...{ auth }}
              />
            ) : openModal === "figma" ? (
              <Figma {...{ auth, app_id }} />
            ) : (
              <div>pricing</div>
            )}
          </div>
        </div>
      )}
      {(installData?.checkInstallation?.is_installed &&
        appData?.getApp?.app_url) ||
      animationsData?.getAppAnimations.length > 0 ? (
        <>
          {" "}
          {/* <AnimationsList
            {...{ removeAnimation, removingAnimation, app_id }}
            getAppAnimations={animationsData?.getAppAnimations}
            {...{ setElement, billing_plan: appData?.getApp?.billing_plan }}
          /> */}
          {installData?.checkInstallation?.is_installed ? (
            <Receiver
              {...{ modalState, element }}
              billing_plan={appData?.getApp?.billing_plan}
              app_url={appData?.getApp?.app_url}
            />
          ) : (
            <>
              {/* <Cloner
                {...{ modalState, element }}
                billing_plan={appData?.getApp?.billing_plan}
                app_url={appData?.getApp?.app_url}
                cloned_url={clonedUrl || appData?.getApp?.cloned_url}
                existingAnimations={animationsData?.getAppAnimations}
                platform={installData?.checkInstallation?.platform}
                setWindowModal={setOpenModal}
              /> */}
              <PlatformSelect />
            </>
          )}
        </>
      ) : (
        <section className="flex flex-col h-4/5 justify-evenly py-22">
          <div class="flex px-12 ">
            <div className="w-full mx-4 ">
              <Cloner
                {...{ modalState, element }}
                billing_plan={appData?.getApp?.billing_plan}
                app_url={appData?.getApp?.app_url}
                cloned_url={clonedUrl || appData?.getApp?.cloned_url}
                setWindowModal={setOpenModal}
              />
            </div>

            {/* <div className="w-1/3 mx-4 fade-in">
              <Playground
                {...{ modalState, element }}
                billing_plan={appData?.getApp?.billing_plan}
                app_url={appData?.getApp?.cloned_url}
              />
            </div> */}
          </div>
          <h3 className="font-normal text-slate-500 text-center text-sm delayed-fade-animation-2">
            Rombo is not installed at{" "}
            <span className="font-normal underline underline-offset-1 decoration-slate-300">
              {appData?.getApp?.app_url?.replace("https://", "")}
            </span>
          </h3>
        </section>
      )}
    </section>
  );
}

export default Animations;
