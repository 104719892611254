
async function triggerStripeBillingPortal(
  app_id,
  stripe_customer_id,
  stripe_subscription_id,
  customer_email
) {
  const sessionResponse = await fetch(
    "https://rea3dpzt4a.execute-api.eu-west-1.amazonaws.com/dev/stripeBillingSession",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: {
          app_id,
          stripe_customer_id,
          stripe_subscription_id,
          customer_email,
        },
      }),
    }
  );

  const session = await sessionResponse.json();

  // Now we have the session ID
  const sessionId = session.id;
  const sessionUrl = session.url;

  window.open(sessionUrl, "_blank");
}

export default triggerStripeBillingPortal;
