import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { AppSync } from "./aws-exports.js";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  useQuery,
  createHttpLink,
  useMutation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Verify } from "./Pages/Verify";
import { Setup } from "./Pages/Setup";

import Animations from "./Pages/Animations";
import AnimationLibrary from "./Components/AnimationsLibrary/AnimationsLibrary";
import Settings from "./Pages/Settings";
import SidePanel from "./Components/SidePanel";
import { GET_USER, GET_APP } from "./Queries";
import { UPDATE_USER } from "./Mutations";
import DemoSlider from "./Components/DemoCarousel";
import { Toaster } from "react-hot-toast";
import triggerStripeCheckout from "./Helpers/stripeTrigger";
import { WebflowSetup } from "./Pages/WebflowSetup";

const httpLink = createHttpLink({
  uri: AppSync.aws_appsync_graphqlEndpoint,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function protectedRoute({ component, auth, appId, app_url }) {
  return auth && appId ? (
    component
  ) : auth && (!appId || !app_url) ? (
    <Navigate to={`/setup`} />
  ) : (
    <Navigate to="/signup" />
  );
}

function WrappedRouter({ auth }) {
  const [updateUser] = useMutation(UPDATE_USER);
  const [pricingModal, setPricingModal] = useState(false);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER, {
    variables: { user_id: auth?.attributes?.username },
  });

  let appId =
    auth && auth.attributes
      ? auth.attributes["custom:app_id"]
      : auth && auth.signInUserSession
      ? auth.signInUserSession.idToken.payload["custom:app_id"]
      : userData && userData.userData.app_id
      ? userData.userData.app_id
      : null;

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery(GET_APP, {
    variables: { app_id: appId },
  });
  // Get path from  react-router

  const { app_name, app_url, admin_name } = appData?.getApp || {};

  async function updateUserName() {
    await updateUser({
      variables: {
        user_id: auth.username,
        full_name: auth.attributes.name,
        email: auth.attributes.email,
        name: app_name || null,
        app_id: appId,
      },
    });
  }

  React.useEffect(() => {
    if (
      auth &&
      auth.attributes &&
      auth.attributes.name &&
      userData &&
      !userData?.getUser?.full_name
    ) {
      try {
        updateUserName();
      } catch (e) {
        console.log(e);
      }
    }
  }, [userData]);

  if (userError || appError) {
    console.log("Error", userError || appError);
  }
  if (!userLoading && !appLoading)
    return (
      <Router>
        {pricingModal && (
          <div
            onClick={() => {
              setPricingModal(false);
            }}
            className="w-full align-center justify-center bg-white bg-opacity-70 items-center flex fixed backdrop-blur-sm h-full left-0 z-50"
          >
            <div
              className="w-1/2 h-2/3"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                onClick={() => {
                  setPricingModal(false);
                }}
                className="opacity-50 hover:opacity-100 cursor-pointer text-md p-2 rounded-md"
              >
                Close x
              </div>
              <div class="flex">
                <div class="w-1/2 m-1 flex flex-col justify-between px-4 py-2 text-gray-600 bg-white rounded-sm outline outline-1 outline-gray-200">
                  <div>
                    <div class="flex ">
                      <div class="font-semibold">Basic</div>
                      <div class="font-semibold pl-3">
                        <span class="text-xs">$</span>0
                      </div>
                    </div>
                    <div>» Up to 3 Animations </div>
                    <div>» Up to 1,000 page views /month</div>
                  </div>
                  <a
                    onClick={() => {
                      setPricingModal(false);
                    }}
                    class="opacity-50 font-semibold"
                  >
                    Continue with basic »
                  </a>
                </div>
                <div class="w-1/2 m-1 px-4 py-2 bg-primary-300 border border-primary-500 outline outline-1 outline-primary-100 rounded-sm">
                  <div>
                    <div class="flex ">
                      <div class="font-semibold">Plus</div>
                      <div class="font-semibold pl-3">
                        <span class="text-xs">$</span>9 / month
                        <span class="text-xs ml-3 bg-primary-600 rounded-sm p-0.5">
                          PH50 / -50% 6 MONTHS
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>» ∞ Animations</div>
                  <div>» 1M page views /monthn</div>
                  <div>» Early access to new animations</div>
                  <div>» Early access to Figma Plugin</div>
                  <button
                    onClick={async (e) => {
                      document.body.style = "cursor: wait !important;";
                      e.target.style = "cursor: wait !important;";
                      await triggerStripeCheckout(appId, auth.attributes.email);
                    }}
                    class="mt-2 py-1 px-3 hover:opacity-50 flex shadow-sm border border-dark-100 shadow-gray-200 bg-primary-900"
                  >
                    <svg
                      class="w-4 mr-1 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                      <path
                        fill-rule="evenodd"
                        d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                        clip-rule="evenodd"
                      />
                      <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                    </svg>
                    Upgrade to Plus
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#010101",
              color: "#fff",
            },
            iconTheme: {
              primary: "#FDF566",
              secondary: "#010101",
            },

            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: "#FDF566",
                secondary: "#010101",
              },
            },
          }}
        />
        <SidePanel
          {...{ auth }}
          {...{ setPricingModal }}
          userData={userData}
          appId={appId}
        />
        <main class={"ml-60"}>
          <Routes>
            <Route path="animations">
              <Route
                path=":app_id"
                index
                element={
                  auth && appId && app_url ? (
                    protectedRoute({
                      component: <Animations {...{ auth }} />,
                      auth,
                      appId,
                      app_url,
                    })
                  ) : (
                    <Navigate to="/setup" />
                  )
                }
              />
            </Route>
            <Route path="library">
              <Route
                path=":app_id"
                index
                element={protectedRoute({
                  component: <AnimationLibrary />,
                  auth,
                  appId,
                })}
              />
            </Route>
            <Route path="settings">
              <Route
                path=":app_id"
                index
                element={
                  auth && appId && admin_name && appData.getApp ? (
                    protectedRoute({
                      component: (
                        <Settings
                          user_id={
                            auth.attributes
                              ? auth.attributes.sub
                              : `${auth.username}`
                          }
                        />
                      ),
                      auth,
                      appId,
                    })
                  ) : (
                    <Navigate to="/setup" />
                  )
                }
              />
            </Route>
            <Route
              path="setup"
              element={
                auth && appId && admin_name && app_url ? (
                  <Navigate to={`/animations/${appId}`} />
                ) : auth &&
                  (auth.attributes || auth.signInUserSession) &&
                  (!appId || !admin_name || !app_url) ? (
                  <Setup
                    {...{ app_name, app_url, admin_name, appId, auth }}
                    user_id={
                      auth.attributes ? auth.attributes.sub : auth.username
                    }
                    email={
                      auth.attributes
                        ? auth.attributes.email
                        : auth.signInUserSession.idToken.payload.email
                    }
                  />
                ) : (
                  <Navigate to="/signup" />
                )
              }
            />
            <Route path="webflow-setup" element={<WebflowSetup />} />
            <Route
              path="signup"
              element={
                auth && appId ? (
                  <Navigate to={`/animations/${appId}`} />
                ) : auth && !appId ? (
                  <Navigate to={`/setup`} />
                ) : (
                  <aside class="demo-slider absolute left-2/4 w-2/4 h-full overflow-hidden">
                    <DemoSlider />
                  </aside>
                )
              }
            />
            <Route path="login" element={auth ? <Navigate to="/" /> : null} />

            <Route
              path="verify/:email/:code"
              element={
                auth && appId ? (
                  <Navigate to={`/animations/${appId}`} />
                ) : auth && !appId ? (
                  <Navigate to={`/setup`} />
                ) : (
                  <Verify {...{ auth }} />
                )
              }
            />
            <Route
              path="*"
              element={
                auth && appId ? (
                  <Navigate to={`/animations/${appId}`} />
                ) : auth && !appId ? (
                  <Navigate to={`/setup`} />
                ) : (
                  <Navigate to={`/signup`} />
                )
              }
            />
          </Routes>
        </main>
      </Router>
    );
  return "";
}

function App() {
  const [auth, setAuth] = useState(null);
  const [connected, setConnected] = useState(false);

  function eventReceiver(event) {
    try {
      console.log("Received in app", event.data.type);
      if (event.data.type === "ROMBO_SCRIPT") {
        console.log("CONNECTED TO APP!");
        // window.postMessage({ type: "TO_ROMBO_JS", auth: auth }, "*");
        setConnected(true);
      }
    } catch (e) {
      console.log(e, "Event receiver failed");
    }
  }

  useEffect(() => {
    // Post message to parent to update the url
    // if (auth) {
    window.addEventListener("message", eventReceiver, false);
    try {
      console.log("posting message");

      console.log("posted message");
      // window.postMessage({ type: "tester", auth: JSON.stringify(auth) }, "*");
    } catch (e) {
      console.log(e, "Post message failed");
    }
    // }
  }, []);

  function sendAppEvent(auth) {
    window.postMessage({ type: "ROMBO_APP", auth: JSON.stringify(auth) }, "*");
  }

  useEffect(() => {
    console.log("connected", connected, "auth", auth);
    if (connected && auth) {
      console.log("Should send app event to script");
      sendAppEvent(auth);
    }
  }, [connected, auth]);

  useEffect(() => {
    let findToken = async () => {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      console.log("Found authed user", cognitoUser);
      setAuth(cognitoUser);
    };
    findToken().catch((e) => {
      setAuth(false);
      new Error(e);
    });
  }, []);

  if (auth !== null)
    return (
      <ApolloProvider client={client}>
        <WrappedRouter {...{ auth }} />
      </ApolloProvider>
    );
  return null;
}

export default App;
