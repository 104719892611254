import React from "react";

function WebPromo() {
  return (
    <div className="rounded-md h-[350px] overflow-hidden bg-slate-200/50 w-full border border-slate-300 outline outline-4 outline-slate-400/5">
      <div className="flex flex-wrap flex-1 tracking-[-0.05px] p-4">
        <div className="items-center w-full">
          <span>Add and deploy</span>
          <svg
            width="11"
            className="mx-0.5 ml-1 inline-block"
            height="15"
            viewBox="0 0 18 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 18.9062C0 20.9277 1.02539 21.9336 3.06641 21.9336H14.2676C16.3086 21.9336 17.334 20.918 17.334 18.9062V9.27734C17.334 7.26562 16.3086 6.25 14.2676 6.25H3.06641C1.02539 6.25 0 7.25586 0 9.27734V18.9062ZM8.66211 14.2871C8.24219 14.2871 7.89062 13.9453 7.89062 13.5352V3.59375L7.94922 2.13867L7.40234 2.71484L5.88867 4.32617C5.75195 4.48242 5.53711 4.56055 5.35156 4.56055C4.94141 4.56055 4.64844 4.26758 4.64844 3.87695C4.64844 3.66211 4.73633 3.50586 4.88281 3.35938L8.0957 0.253906C8.30078 0.0585938 8.4668 0 8.66211 0C8.86719 0 9.0332 0.0585938 9.22852 0.253906L12.4512 3.35938C12.5977 3.50586 12.6758 3.66211 12.6758 3.87695C12.6758 4.26758 12.373 4.56055 11.9629 4.56055C11.7773 4.56055 11.5723 4.48242 11.4355 4.32617L9.93164 2.71484L9.38477 2.13867L9.44336 3.59375V13.5352C9.44336 13.9453 9.08203 14.2871 8.66211 14.2871Z"
              fill="black"
              fill-opacity="0.85"
            />
          </svg>
          <span>
            {" "}
            animations with our no-code tools straight to any web page
          </span>
        </div>
        <div className="my-6 flex flex-col">
          <button
            className="font-semibold flex items-center bg-slate-900 text-white px-3 py-1.5 outline outline-2 outline-slate-300 rounded-md"
            data-signup
          >
            <svg
              width="11"
              className="mr-2 inline-block"
              height="15"
              viewBox="0 0 18 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 18.9062C0 20.9277 1.02539 21.9336 3.06641 21.9336H14.2676C16.3086 21.9336 17.334 20.918 17.334 18.9062V9.27734C17.334 7.26562 16.3086 6.25 14.2676 6.25H3.06641C1.02539 6.25 0 7.25586 0 9.27734V18.9062ZM8.66211 14.2871C8.24219 14.2871 7.89062 13.9453 7.89062 13.5352V3.59375L7.94922 2.13867L7.40234 2.71484L5.88867 4.32617C5.75195 4.48242 5.53711 4.56055 5.35156 4.56055C4.94141 4.56055 4.64844 4.26758 4.64844 3.87695C4.64844 3.66211 4.73633 3.50586 4.88281 3.35938L8.0957 0.253906C8.30078 0.0585938 8.4668 0 8.66211 0C8.86719 0 9.0332 0.0585938 9.22852 0.253906L12.4512 3.35938C12.5977 3.50586 12.6758 3.66211 12.6758 3.87695C12.6758 4.26758 12.373 4.56055 11.9629 4.56055C11.7773 4.56055 11.5723 4.48242 11.4355 4.32617L9.93164 2.71484L9.38477 2.13867L9.44336 3.59375V13.5352C9.44336 13.9453 9.08203 14.2871 8.66211 14.2871Z"
                fill="white"
                fill-opacity="0.85"
              />
            </svg>
            Animate my site
          </button>
        </div>
      </div>
      <div className="flex items-center mt-[23%] relative">
        <img
          className="w-[150px] rounded-sm h-auto block relative z-20"
          src="https://assets.rombo.co/glow.gif"
        />
        <img
          className="w-[150px] rounded-sm h-auto block absolute z-10 left-[110px]"
          src="https://assets.rombo.co/adventure.gif"
        />
        <img
          className="w-[150px] rounded-sm h-auto block relative z-0 ml-[90px]"
          src="https://assets.rombo.co/scrunch.gif"
        />
      </div>
    </div>
  );
}

export default WebPromo;
